var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ContentContainer"},[_c('div',{staticClass:"columns is-multiline py-2"},[(_vm.text && (!_vm.reverse || !_vm.image))?_c('div',{staticClass:"column",class:{
        'is-7': _vm.text && _vm.image && !_vm.fullwidth,
        'is-8 is-offset-2': _vm.text && !_vm.image && !_vm.fullwidth,
        'is-12': _vm.fullwidth,
      }},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column",class:{
            'is-10 is-offset-2': _vm.text && _vm.image,
          }},[_c('p',{staticClass:"is-text is-size-4-tablet is-size-5-mobile px-5 py-5",class:[
              _vm.textClasses,
              {
                'has-text-black': _vm.dark && !_vm.light,
                'has-text-white': _vm.light,
              } ],domProps:{"innerHTML":_vm._s(_vm.text)}}),_vm._t("default")],2)])]):_vm._e(),(_vm.image)?_c('div',{staticClass:"column",class:{
        'is-5': _vm.text && _vm.image,
        'is-12': !_vm.text && _vm.image,
      }},[_c('div',{staticClass:"columns px-5 mt-5"},[_c('div',{staticClass:"column is-8 is-offset-2"},[_c('div',{staticClass:"image-container",class:{
              black: _vm.dark && !_vm.light,
              white: _vm.light,
            }},[(_vm.link)?[_c('a',{attrs:{"href":_vm.link,"target":"_blank"}},[_c('img',{attrs:{"src":require('@/assets/' + _vm.image + '')}})])]:[_c('img',{attrs:{"src":require('@/assets/' + _vm.image + '')}})]],2)])])]):_vm._e(),(_vm.text && _vm.image && _vm.reverse)?_c('div',{staticClass:"column is-7"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column",class:{
            'is-10': _vm.text && _vm.image,
          }},[_c('p',{staticClass:"is-text is-size-4 px-5 py-5",class:[
              _vm.textClasses,
              {
                'has-text-black': _vm.dark && !_vm.light,
                'has-text-white': _vm.light,
              } ],domProps:{"innerHTML":_vm._s(_vm.text)}}),_vm._t("default")],2)])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }