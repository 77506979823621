<template>
  <div class="MainContainer">
    <div class="container  ">
      <div class="columns">
        <div :style="{backgroundColor: bgColor}" class="column is-12-widescreen is-offset-0-widescreen is-12-desktop is-offset-0-desktop is-12-tablet is-offset-0-tablet ">

          <slot/>

        </div>
      </div>
    </div>
  </div>


</template>

<style lang="scss" scoped>

@media only screen and (max-width: 768px) {
  .MainContainer{
    .container{
      padding: 0;
      .columns{
        .column{

        }
      }
    }
  }
}

.MainContainer{
  min-height: calc(100vh - 107px);
  &>.container{
    &>.columns{
      margin: 0;
      &:last-child {
        margin-bottom: 0 !important;
      }
      &>.column{
        padding: 0;
      }
    }
  }
}

</style>

<script>
export default {
  name: 'MainContainer',
  props: {
    bgColor: {
      required: false,
      default: () => '#141414',

    }
  }
}
</script>
