<template>
    <div class="SectionTitleContainer">
      <div class="columns is-multiline">
        <div class="column is-12 has-text-centered mt-6">
          <h5 class="is-size-1-tablet is-size-3-mobile" :class="[{
            'has-text-white' : light,
            'has-text-black' : dark&&!light,
            'has-text-weight-bold' : bold,
          }]">{{ title }}</h5>
        </div>
      </div>
    </div>
</template>

<script>
export default {
name: "SectionTitleContainer",

  data() {
      return {

      }
  },

  props: {
    title: {
      required: true,
      type: String
    },
    dark: {
      required: false,
      type: Boolean,
      default() {
        return true
      }
    },
    light: {
      required: false,
      type: Boolean,
      default() {
        return false
      }
    },

    bold: {
      required: false,
      type: Boolean,
      default() {
        return false
      }
    },

  },


}

</script>
