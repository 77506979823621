
<!--
This component expects to get an object item like:
{
    'text': 'I have a 10 year old son. He has words. He is so good with these words it\'s unbelievable. Look at these words. Are they small words? And he referred to my words - if they\'re small, something else must be small. I have a 10 year old son. He has words. He is so good with these words it\'s unbelievable. Look at these words.',
    'image': 'images/bread_outside.jpeg' // path from assets folder
}
or
{
    'text': 'I have a 10 year old son. He has words. He is so good with these words it\'s unbelievable. Look at these words. Are they small words? And he referred to my words - if they\'re small, something else must be small. I have a 10 year old son. He has words. He is so good with these words it\'s unbelievable. Look at these words.',
}
or
{
    'image': 'images/bread_outside.jpeg' // path from assets folder
}

can also add the reverse attribute to switch the image and the text

@dev.note:  v-html="text"

-->

<template>
  <div class="ContentContainer">
    <div class="columns is-multiline py-2">
      <div
        class="column"
        :class="{
          'is-7': text && image && !fullwidth,
          'is-8 is-offset-2': text && !image && !fullwidth,
          'is-12': fullwidth,
        }"
        v-if="text && (!reverse || !image)"
      >
        <div class="columns">
          <div
            class="column"
            :class="{
              'is-10 is-offset-2': text && image,
            }"
          >
            <p
              class="is-text is-size-4-tablet is-size-5-mobile px-5 py-5"
              :class="[
                textClasses,
                {
                  'has-text-black': dark && !light,
                  'has-text-white': light,
                },
              ]"
              v-html="text"
            ></p>
            <slot />
          </div>
        </div>
      </div>

      <div
        class="column"
        :class="{
          'is-5': text && image,
          'is-12': !text && image,
        }"
        v-if="image"
      >
        <div class="columns px-5 mt-5">
          <div class="column is-8 is-offset-2">
            <div
              class="image-container"
              :class="{
                black: dark && !light,
                white: light,
              }"
            >
              <template v-if="link">
                <a :href="link" target="_blank">
                  <img :src="require('@/assets/' + image + '')" />
                </a>
              </template>
              <template v-else>
                <img :src="require('@/assets/' + image + '')" />
              </template>
            </div>
          </div>
        </div>
      </div>

      <div class="column is-7" v-if="text && image && reverse">
        <div class="columns">
          <div
            class="column"
            :class="{
              'is-10': text && image,
            }"
          >
            <p
              class="is-text is-size-4 px-5 py-5"
              :class="[
                textClasses,
                {
                  'has-text-black': dark && !light,
                  'has-text-white': light,
                },
              ]"
              v-html="text"
            ></p>
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.image-container {
  padding: 30px;

  &.white {
    border: 5px solid #f8f8ff;
  }

  &.black {
    border: 5px solid #000000;
  }
}
</style>

<script>
export default {
  name: "ContentContainer",

  data() {
    return {
      text: false,
      image: false,
    };
  },

  props: {
    content: {
      required: true,
      type: Object,
    },
    link: {
      required: false,
      default: false,
    },
    reverse: {
      required: false,
      type: Boolean,
      default() {
        return false;
      },
    },
    dark: {
      required: false,
      type: Boolean,
      default() {
        return true;
      },
    },
    light: {
      required: false,
      type: Boolean,
      default() {
        return false;
      },
    },
    fullwidth: {
      required: false,
      type: Boolean,
      default() {
        return false;
      },
    },
    textClasses: {
      required: false,
      type: String,
      default() {
        return "has-text-justified has-text-left-mobile";
      },
    },
  },

  created() {
    let self = this;

    self.text =
      typeof self.content.text !== "undefined" ? self.content.text : false;
    self.image =
      typeof self.content.image !== "undefined" ? self.content.image : false;
  },

  methods: {},
};
</script>

